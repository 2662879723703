.artifacts-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  overflow: hidden;
  height: 100%; /* Ensure it takes full height of its container */
}

.document-iframe {
  flex: 1;
  width: 100%;
  border: none;
  background-color: var(--gradient-white);
}

.version-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--lightest-grey);
}

.download-container {
  position: relative;
}

.download-button {
  padding: 5px 10px;
  background-color: var(--default-orange);
  color: var(--gradient-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: var(--gradient-white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  margin-bottom: 5px;
}

.dropdown-content.show {
  display: block;
}

.dropdown-content a {
  color: var(--default-brown);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: var(--light-orange);
}

.action-items-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--gradient-white);
}

.action-items-section {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(50% - 30px); /* Adjust this value as needed */
  background: var(--gradient-white);
  border-bottom: 1px solid var(--light-grey);
}

.action-items-section h2 {
  color: var(--default-brown);
  margin-bottom: 15px;
}

.action-item {
  border: 2px solid var(--light-orange);
  border-radius: 8px;
  padding: 0px 15px;
  margin-bottom: 15px;
  background-color: var(--gradient-cream);
}

.action-item h3 {
  color: var(--default-brown);
  margin-bottom: -5px;
}

.action-item p {
  color: var(--light-brown);
  margin-bottom: 15px;
}

.action-item-button {
  padding: 5px 10px;
  margin-bottom: 15px;
  border: 1px solid var(--default-orange);
  border-radius: 4px;
  background: none;
  color: var(--default-orange);
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-item-button:hover {
  background-color: var(--hover-orange);
  color: var(--gradient-white);
}

.save-action-items-bar {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: var(--lightest-grey);
}

.save-action-items-button {
  padding: 5px 10px;
  background-color: var(--default-orange);
  color: var(--gradient-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-action-items-button:hover {
  background-color: var(--hover-orange);
}

/* New styles to ensure proper layout */
.action-items-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.action-items-scroll {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}