/* src/AccessCodePage.css */
.access-code-form {
  background-color: var(--lightest-orange);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.access-code-title {
  color: var(--default-brown);
  margin-bottom: 1rem;
}

.access-code-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid var(--light-grey);
  font-size: 1rem;
}

.access-code-button {
  background-color: var(--default-orange);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.access-code-button:hover {
  background-color: var(--hover-orange);
}

.access-code-error {
  color: red;
  margin-top: 1rem;
}