.tabContainer {
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.tabContainer::-webkit-scrollbar {
  height: 6px;
}

.tabContainer::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.tabContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.tabButton {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  flex: 1 1 0;
  min-width: max-content;
  text-align: center;
}

.tabButton:hover {
  background-color: #e0e0e0;
}

.tabButton.active {
  background-color: var(--active-yellow);
  font-weight: bold;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tabContent {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 60vh;
  overflow-y: auto;
}

.tabContent h2 {
  color: var(--default-brown);
  margin-bottom: 15px;
}

.matchBox {
  display: flex;
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.matchImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.matchInfo {
  flex: 1;
}

.matchInfo h3 {
  margin-top: 0;
  color: var(--default-brown);
}

.matchQuality {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.updateButton {
  align-self: flex-end;
  background-color: var(--default-orange);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.updateButton:hover {
  background-color: var(--hover-orange);
}

.updateButton:disabled {
  background-color: var(--light-grey);
  cursor: not-allowed;
}