.actionItemsContainer {
  width: 100%;
}

.actionItemsList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 15px;
}

.actionItem {
  border: 2px solid var(--light-orange);
  border-radius: 8px;
  padding: 0px 15px;
  margin-right: 15px;
  background-color: var(--gradient-cream);
  width: calc(33.33% - 10px);
  min-width: 200px;
  max-height: 200px;
  overflow-y: auto;
}

.actionItem h3 {
  color: var(--default-brown);
  margin-bottom: -5px;
}

.actionItem p {
  color: var(--light-brown);
  margin-bottom: 15px;
}

.removeButton {
  padding: 5px 10px;
  margin-bottom: 15px;
  border: 1px solid var(--default-orange);
  border-radius: 4px;
  background: none;
  color: var(--default-orange);
  cursor: pointer;
  transition: all 0.3s ease;
}

.removeButton:hover {
  background-color: var(--hover-orange);
  color: var(--gradient-white);
}

.addItemForm {
  margin-top: 15px;
  max-width: calc(100% - 20px);
}

.inputField {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid var(--light-orange);
  border-radius: 4px;
  font-size: 14px;
}

.inputField:focus {
  outline: none;
  border-color: var(--default-orange);
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.2);
}

.formButtons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.formButtons .removeButton {
  margin-bottom: 0;
}