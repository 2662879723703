.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  color: var(--default-brown);
  box-sizing: border-box;
}

.signInButton {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signInButton:hover {
  background-color: var(--hover-orange);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heroSection {
  text-align: center;
  margin-bottom: 2rem;
}

.title {
  font-size: clamp(2rem, 5vw, 3rem);
  color: var(--default-orange);
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin-bottom: 1rem;
}

.chatContainer {
  width: 100%;
  max-width: 800px;
  margin-bottom: 3rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.featuresContainer {
  width: 100%;
  max-width: 800px;
}

.featuresTitle {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--default-orange);
}

.tabsContainer {
  display: flex;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: var(--default-orange) var(--lightest-orange);
}

.tabsContainer::-webkit-scrollbar {
  height: 6px;
}

.tabsContainer::-webkit-scrollbar-track {
  background: var(--lightest-orange);
}

.tabsContainer::-webkit-scrollbar-thumb {
  background-color: var(--lightest-orange);
  border-radius: 3px;
}

.tabButton {
  background-color: var(--lightest-orange);
  border: none;
  padding: 0.75rem 1.5rem;
  margin-right: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s;
  font-size: clamp(0.8rem, 2vw, 1rem);
}

.tabButton:hover {
  background-color: var(--light-orange);
}

.activeTab {
  background-color: var(--default-orange);
  color: white;
}

.tabContent {
  background-color: var(--lightest-orange);
  padding: 1.5rem;
  border-radius: 5px;
}

.tabContent h3 {
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  margin-bottom: 1rem;
  color: var(--default-orange);
}

.tabContent p {
  margin-bottom: 1rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.tabContent h4 {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  margin-bottom: 0.5rem;
}

.tabContent ul {
  list-style-type: none;
  padding-left: 0;
}

.tabContent li {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
  font-size: clamp(0.8rem, 2vw, 1rem);
}

.tabContent li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--default-orange);
}

@media (max-width: 768px) {
  .landingPage {
    padding: 1rem 0.5rem;
  }

  .signInButton {
    top: 10px;
    right: 10px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .chatContainer {
    margin-bottom: 2rem;
  }

  .tabsContainer {
    margin-bottom: 1rem;
  }

  .tabButton {
    padding: 0.5rem 1rem;
  }

  .tabContent {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .signInButton {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .tabButton {
    padding: 0.4rem 0.8rem;
  }
}