.layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.mainContent {
  flex-grow: 1;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .layout {
    flex-direction: column;
  }

  .mainContent {
    padding-top: 60px; /* To account for the mobile toggle button */
  }
}