:root {
  --gradient-white: #ffffff;
  --gradient-gold: #ffd700;
  --gradient-orange: #ffb060;
  --gradient-cream: #fff9e6;
  
  --default-orange: #ffa500;
  --default-brown: #673512;
  --light-brown: #8b4513;
  --hover-orange: #ff8c00;
  --light-orange: #ffdab9;
  --lightest-orange: #fff2e6;
  --active-yellow: #ffd700; /* For active tabs */

  --light-grey: #e0e0e0;
  --lightest-grey: #f1f1f1;
  --mid-grey: #c0c0c0;
}