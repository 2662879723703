.sign-in-page {
  min-width: 300px;
  max-width: 700px;
  margin: 50px auto;
  padding: 20px;
  background-color: var(--gradient-cream);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sign-in-page h1 {
  text-align: center;
  color: var(--default-brown);
  margin-bottom: 20px;
}

.sign-in-page form {
  display: flex;
  flex-direction: column;
}

.sign-in-page input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--mid-grey);
  border-radius: 4px;
  font-size: 16px;
}

.sign-in-page .sign-in-button {
  padding: 10px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 15px;
}

.sign-in-page .sign-in-button:hover {
  background-color: var(--hover-orange);
}

.sign-in-page .sign-in-button:disabled {
  background-color: var(--light-grey);
  cursor: not-allowed;
}

.sign-in-page .text-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.sign-in-page .text-button {
  background: none;
  border: none;
  color: var(--default-brown);
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.sign-in-page .text-button:hover {
  color: var(--hover-orange);
}

.sign-in-page .password-input-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sign-in-page .password-input-container input {
  width: 100%;
  margin-bottom: 0;
}

.sign-in-page .password-toggle-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--default-brown);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-page .password-toggle-button:hover {
  color: var(--hover-orange);
}