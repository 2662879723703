.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  overflow: hidden;
  height: 90vh;
}

.noSidebar {
  height: auto;
}

.tabsContainer {
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.tabsContainer::-webkit-scrollbar {
  display: none; /* WebKit */
}

.tabButton {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  color: var(--default-brown);
  cursor: pointer;
  margin-right: 5px;
  flex-shrink: 0;
}

.activeTab {
  background-color: var(--active-yellow);
  font-weight: bold;
  border-bottom: 2px solid var(--default-orange);
}

.tabContentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 400px;
}

/* Make chatbox shorter if viewport width goes below 767px.
 This is because at 767px, the sidebar becomes collapsible, adding
 padding to the top, so the chatbox must be shorter to look good on mobile */
@media (max-width: 767px) {
  .tabContentWrapper {
    max-height: 70vh;
  }
}

.tabContent {
  flex: 1;
  display: flex;
  overflow: hidden;
  width: 80vw; /* Set width to 80% of viewport width */
  max-width: 1200px; /* Optional: set a maximum width */
  margin: 0 auto; /* Center the content */
}

.chatContainer {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  overflow: hidden;
}

.chatWrapper {
  flex: 1;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.chatComponent {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

/* Ensure DeepChat component fills its container */
.chatComponent > div {
  width: 100% !important;
  height: 100% !important;
}