.pageContainer {
  background-color: var(--color-cream);
  min-height: 80vh;
  background: linear-gradient(
    to bottom, 
    var(--gradient-white) 0%, 
    var(--gradient-gold) 20%, 
    var(--gradient-orange) 23%, 
    var(--gradient-cream) 30%, 
    var(--gradient-cream) 100%
  );
  /* border: 5px solid red; */
}

.mainContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--default-brown);
  padding: 15px 0;
  margin-bottom: 20px;
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleContainer h1 {
  margin-bottom: 5px;
  padding: 0;
}

.titleContainer h4 {
  color: var(--light-brown);
  margin-top: 0;
  font-weight: 500;
}