.error-screen {
    min-height: 100vh;
    background-color: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }
  
  .error-container {
    max-width: 28rem;
    width: 100%;
  }
  
  .error-content {
    text-align: center;
  }
  
  .error-icon {
    margin: 0 auto;
    height: 3rem;
    width: 3rem;
    color: #ef4444;
  }
  
  .error-title {
    margin-top: 1.5rem;
    font-size: 1.875rem;
    font-weight: 800;
    color: #111827;
  }
  
  .error-message {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #4b5563;
  }
  
  .error-action {
    margin-top: 2rem;
  }
  
  .retry-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: #4f46e5;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .retry-button:hover {
    background-color: #4338ca;
  }
  
  .retry-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e0e7ff;
  }