.creditScoreBox {
  background-color: #fffaf0;
  border: 1px solid #ffdab9;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.expanded {
  height: auto;
}

.creditScoreBox h2 {
  color:#673512;
  margin-top: 0;
}

.creditScoreWheel {
  width: 200px;
  margin: 0 auto;
}

.circularChart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circleBg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
  stroke: #ffa500;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.lastUpdated {
  color: #8b4513;
  font-size: 0.9em;
  margin-top: 10px;
}

.toggleButton {
  background-color: var(--default-orange);
  border: none;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.expandedContent {
  margin-top: 20px;
  text-align: left;
  padding-bottom: 50px;
}

.financialList {
  list-style-type: none;
  padding: 0;
}

.financialList li {
  margin-bottom: 5px;
}

.creditScoreHistory {
  margin-top: 20px;
  height: calc(70vh - 40px); /* Set the height to 70% of the viewport height */
  max-height: 460px; /* Set a maximum height to prevent it from becoming too large on big screens */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chartContainer {
  flex-grow: 1;
  position: relative; /* Needed for chart.js to size correctly */
  min-height: 300px;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}