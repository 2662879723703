.verification-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: var(--gradient-cream);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.verification-page h1 {
  color: var(--default-brown);
  margin-bottom: 20px;
}

.verification-page p {
  color: var(--light-brown);
  margin-bottom: 20px;
}

.verification-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verification-page input {
  width: 200px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--mid-grey);
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 5px;
}

.verification-page button {
  width: 200px;
  padding: 10px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verification-page button:hover {
  background-color: var(--hover-orange);
}

.verification-page button:disabled {
  background-color: var(--light-grey);
  cursor: not-allowed;
}