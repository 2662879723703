.choose-account-type-page {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: var(--gradient-cream);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.choose-account-type-page h1 {
  color: var(--default-brown);
  margin-bottom: 20px;
}

.choose-account-type-page p {
  color: var(--light-brown);
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.choose-account-type-page button {
  padding: 10px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.choose-account-type-page button:hover {
  background-color: var(--hover-orange);
}