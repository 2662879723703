/* src/Documents.module.css */
.searchBox {
  background-color: #fffaf0;
  border: 1px solid #ffdab9;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.searchBox input {
  width: calc(100% - 16px); /* Subtract padding from width */
  padding: 8px;
  border: 1px solid #ffdab9;
  border-radius: 3px;
  font-size: 16px; /* Increase font size */
}

.searchBox input::placeholder {
  font-size: 16px; /* Ensure placeholder text is also larger */
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fffaf0;
  border: 1px solid #ffdab9;
  border-radius: 5px;
  overflow: hidden;
}

th {
  background-color: #ffd7a0;
  color: #673512;
  padding: 12px;
  text-align: left;
}

td {
  padding: 10px;
  border-top: 1px solid #ffdab9;
}

th:first-child, td:first-child {
  padding-left: 15px;
}

th:last-child, td:last-child {
  padding-right: 15px;
}

a {
  color: #673512;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}