/* src/Home.module.css */
.container {
  font-family: Arial, sans-serif;
  background-color: #fff9e6;
  min-height: 100vh;
}
  
.mainContent {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  gap: 20px; /* This adds gaps between flex items */
}

.contentBox {
  background-color: #fffaf0;
  border: 1px solid #ffdab9;
  border-radius: 5px;
  padding: 20px;
  flex: 1; /* This allows boxes to grow and shrink equally */
  min-width: 0; /* This prevents flex items from overflowing */
}
  
.contentBox h2 {
  color: var(--default-brown);
  margin-top: 0;
}

.mainButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 10px;
}

.mainButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--default-orange);
  color: var(--gradient-white);
  cursor: pointer;
  transition: all 0.3s ease;
}

.mainButton:hover {
  background-color: var(--hover-orange);
}