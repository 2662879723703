.editButton {
  padding: 10px 20px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  cursor: pointer;
  /* margin-top: 10px; */
  border-radius: 5px;
  transition: background-color 0.3s;
}

.editButton:hover {
  background-color: var(--hover-orange);
}

.editPanel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.editPanel form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editPanel input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.editPanel button {
  padding: 10px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.editPanel button:hover {
  background-color: var(--hover-orange)
}

.editPanel button[type="button"] {
  background-color: var(--light-grey);
}

.editPanel button[type="button"]:hover {
  background-color: var(--mid-grey);
}

.userProfileBox {
  margin-top: 20px;
  background-color: #fffaf0;
  border: 1px solid #ffdab9;
  padding: 20px;
  border-radius: 5px;
}

.profileCategory {
  margin-bottom: 30px;
}

.profileCategory h3 {
  margin-bottom: 5px;
}

.categoryLabel {
  font-style: italic;
  color: #666;
  margin-bottom: 15px;
}

.profileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid #eee;
  margin-bottom: 5px;
}

.profileItem button {
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
}

.profileItem button:nth-last-child(2) {
  margin-right: 5px;
}

.profileItem span {
  flex-grow: 1;
}

.saveProfileButton {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  cursor: pointer;
}

.smallButton {
  background-color: var(--default-orange);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

h1, h2, h3, h4 {
  color: var(--default-brown);
}

.userProfileBox h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
}