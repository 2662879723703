.create-account-page {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: var(--gradient-cream);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
.create-account-page h1 {
  text-align: center;
  color: var(--default-brown);
  margin-bottom: 20px;
}

.create-account-page form {
  display: flex;
  flex-direction: column;
}

.create-account-page input,
.create-account-page select {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--mid-grey);
  border-radius: 4px;
  font-size: 16px;
}

.create-account-page button {
  padding: 10px;
  background-color: var(--default-orange);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-account-page button:hover {
  background-color: var(--hover-orange);
}

.create-account-page button:disabled {
  background-color: var(--light-grey);
  cursor: not-allowed;
}

.create-account-page h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--light-brown);
}

.create-account-page .optional-fields {
  border-top: 1px solid var(--mid-grey);
  padding-top: 20px;
  margin-top: 20px;
}

.create-account-page .back-button {
  margin-top: 10px;
  padding: 10px;
  background-color: var(--light-brown);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
  
.create-account-page .back-button:hover {
  background-color: var(--default-brown);
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.checkbox-group .MuiFormControlLabel-root {
  margin-left: 0;
}

.error-text {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}