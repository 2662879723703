.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f3f4f6;
}

.logo-container {
  position: relative;
  width: 128px;
  height: 128px;
}

.logo, .logo-arc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-text {
  margin-top: 16px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #374151;
}