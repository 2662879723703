.sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--gradient-cream);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--light-orange);
}

.logoContainer {
  padding: 20px;
  text-align: center;
}

.logo {
  max-width: 100%;
  height: auto;
}

.nav {
  flex-grow: 1;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navItem {
  margin-bottom: 0;
}

.navLink {
  display: block;
  padding: 10px 20px;
  color: var(--light-brown);
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.navLink:hover {
  background-color: var(--light-orange);
}

.activeLink {
  font-weight: bold;
  background-color: var(--active-yellow);
}

.submenu {
  list-style-type: none;
  padding-left: 20px;
}

.submenuLink {
  display: block;
  padding: 5px 10px;
  color: var(--light-brown);
  text-decoration: none;
  font-size: 0.9em;
}

.submenuLink:hover {
  background-color: var(--light-orange);
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    z-index: 1000;
    transition: left 0.3s ease;
  }

  .sidebar.open {
    left: 0;
  }

  .sidebar.mobile .toggleButton {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: var(--gradient-cream);
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1001;
  }

  .sidebar.open.mobile .toggleButton {
    left: 260px;
  }
}